import { Override } from "../types/index";
import { byKey, transformMap } from "../utils/arrays";
import { dateToStr, strToDate } from "../utils/dates";
import { createReturnFormatArray } from "../utils/promise";
import { SyncTransparency } from "./CalendarSyncPolicy";
import {
  Calendar as CalendarDto,
  SyncCalendarType as SyncCalendarTypeDto,
  SyncSettings as SyncSettingsDto,
} from "./client";
import { EventColor, EventType } from "./EventMetaTypes";
import { TransformDomain } from "./types";
import { User } from "./Users";

export enum DayOfWeek {
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
}

export const Weekdays = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
];

export const AllDays = [...Weekdays, DayOfWeek.Saturday, DayOfWeek.Sunday];

export enum CalendarType {
  Primary = "PRIMARY",
  Shadow = "SHADOW",
  Personal = "PERSONAL",
  Priority = "PRIORITY",
}

export enum SyncCalendarType {
  Business = "BUSINESS",
  Personal = "PERSONAL",
  Travel = "TRAVEL",
}

export type SyncSettings = Override<
  SyncSettingsDto,
  {
    transparency: SyncTransparency;
    workingHours: boolean;
    defaultType: EventType;
    color: EventColor;
    type: SyncCalendarType;
  }
>;

type CalendarT = Override<
  CalendarDto,
  {
    readonly id: number;
    readonly calendarId: string;
    readonly primaryCalendarId?: string;

    readonly name: string;
    readonly authorized: boolean;
    readonly credentialId?: number;
    readonly accessDomainRead?: boolean;
    readonly colorHex?: string;

    readonly created?: Date;
    readonly deleted?: Date;

    syncSettings: SyncSettings;
    data?: any;

    lastSynced?: Date;
  }
>;

export class Calendar implements CalendarT {
  readonly id: number;
  readonly userId: string;
  readonly calendarId: string;
  readonly primaryCalendarId?: string;

  readonly name: string;
  readonly authorized: boolean;
  readonly credentialId?: number;
  readonly accessDomainRead?: boolean;
  readonly colorHex?: string;

  readonly created?: Date;
  readonly deleted?: Date;

  syncSettings: SyncSettings;
  data?: any;

  lastSynced?: Date;

  constructor(data?: Partial<Calendar>) {
    if (data) Object.entries(data).forEach(([k, v]) => (this[k] = v));
  }

  // TODO (IW): Find a better place for this (and Habit.getColor, Task.getColor)
  static getColor(user: User, calendar: Calendar): EventColor | undefined {
    return [EventColor.Auto, EventColor.None].includes(calendar?.syncSettings.color)
      ? Calendar.getDefaultColor(user, calendar)
      : calendar?.syncSettings.color;
  }

  static getDefaultColor(user: User, calendar: Calendar): EventColor | undefined {
    return !!calendar?.colorHex
      ? new EventColor(EventColor.None.key, null, calendar.colorHex, "Calendar default")
      : EventColor.None;
  }
}

export function dtoToCalendar(dto: CalendarDto): Calendar {
  const syncSettings: SyncSettings = {
    ...dto.syncSettings,
    transparency: dto.syncSettings.transparency as unknown as SyncTransparency,
    workingHours: !!dto.syncSettings.workingHours,
    defaultType: !!dto.syncSettings?.defaultType ? EventType.get(dto.syncSettings.defaultType) : EventType.Personal,
    color: EventColor.get(dto.syncSettings?.color) || EventColor.Auto,
    type: dto.syncSettings.type as unknown as SyncCalendarType,
  };

  return {
    ...dto,
    id: dto.id as number,
    userId: dto.userId as string,
    calendarId: dto.calendarId as string,
    name: dto.name as string,
    authorized: !!dto.authorized,
    lastSynced: strToDate(dto.lastSynced),
    created: strToDate(dto.created),
    deleted: strToDate(dto.deleted),
    syncSettings,
  };
}

export function calendarToDto(calendar: Partial<Calendar>): CalendarDto {
  const syncSettings = !!calendar.syncSettings
    ? {
        ...calendar.syncSettings,
        transparency: calendar.syncSettings.transparency as unknown as SyncSettingsDto["transparency"],
        color: (EventColor.Auto === calendar.syncSettings.color
          ? null
          : calendar.syncSettings.color?.toJSON()) as SyncSettingsDto["color"],
        defaultType: calendar.syncSettings.defaultType?.toJSON() as SyncSettingsDto["defaultType"],
        type: calendar.syncSettings.type ? SyncCalendarTypeDto[calendar.syncSettings.type] : undefined,
      }
    : ({} as SyncSettingsDto);

  return {
    ...calendar,
    syncSettings,
    lastSynced: dateToStr(calendar.lastSynced),
    created: dateToStr(calendar.created),
    deleted: dateToStr(calendar.deleted),
  };
}

export class CalendarsDomain extends TransformDomain<Calendar, CalendarDto> {
  resource = "Calendar";
  cacheKey = "calendars";
  pk = "id";

  public serialize = calendarToDto;
  public deserialize = dtoToCalendar;

  getPrimary = () => {
    return this.api.calendars
      .getPrimary()
      .catch((err) => {
        switch (err.status) {
          case "NetworkError":
            console.warn("Network error", err);
          // TODO retry logic
          case 500:
            return err;
          case 401:
            return null;
          default:
            throw err;
        }
      })
      .then((res) => {
        return createReturnFormatArray(
          res && !(res instanceof Error) ? this.deserialize(res) : null,
          res instanceof Error ? res : null
        );
      });
  };

  listPersonal = () => {
    return this.api.calendars
      .getAllPersonal()
      .catch((err) => {
        switch (err.status) {
          case "NetworkError":
          // TODO retry logic
          case 500:
            return err;
          case 401:
            return null;
          default:
            throw err;
        }
      })
      .then((res) => {
        if (res instanceof Error) {
          return createReturnFormatArray(null, res);
        }
        return createReturnFormatArray(res ? transformMap(res, this.deserialize) : null, null);
      });
  };

  getPersonal = this.deserializeResponse(this.api.calendars.getPersonal);

  listAll = async (): Promise<Calendar[]> => {
    try {
      const [primary] = await this.getPrimary();
      const [personal] = await this.listPersonal();
      const personalCandidates = await this.getPersonalCandidates();

      const checked: string[] = (personal || []).map((c) => c.calendarId);
      let candidates = [
        ...(personal || []),
        ...(personalCandidates || []).filter((c) => !checked.includes(c.calendarId)),
      ];
      const nonPrimary = candidates
        .sort(byKey("name"))
        .filter((c) => (!!c && !c.data.primary) || c.credentialId !== primary?.credentialId);

      if (!primary) return nonPrimary;

      // Sort results by name, but primary is always first if it exists
      return [primary, ...nonPrimary];
    } catch (e) {
      return [];
    }
  };

  deletePersonal = (calendar: Calendar) => this.api.calendars.deletePersonal(calendar.id);

  getPersonalCandidates = this.deserializeResponse((credentialId?: number) =>
    this.api.calendars.getPersonalCandidates({ credentialId })
  );

  getSourceCandidates = this.deserializeResponse((credentialId: number) =>
    this.api.calendars.getSourceCandidates(credentialId)
  );
  getTargetCandidates = this.deserializeResponse((credentialId: number) =>
    this.api.calendars.getTargetCandidates(credentialId)
  );

  createSync = this.deserializeResponse(this.api.calendars.createSync);

  share = (credentialId: number) => this.api.calendars.share({ credentialId }, {});

  getCalendarUrl = (calendar?: Calendar, pathname?: string): string | undefined => {
    if (!calendar) return undefined;

    const url = new URL(`https://calendar.google.com/calendar/r?authuser=${calendar.calendarId}`);
    if (pathname) url.pathname = pathname;

    return url.toString();
  };
}
