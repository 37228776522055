import { BUILD_ID } from "../analytics";
import { AccountsDomain } from "./Accounts";
import { ReclaimWS } from "./adaptors/ws";
import { CalendarsDomain } from "./Calendars";
import { CalendarSyncPolicyDomain } from "./CalendarSyncPolicy";
import { ReclaimApi } from "./client";
import { CredentialsDomain } from "./Credentials";
import { EventsDomain } from "./Events";
import { HabitsDomain } from "./Habits";
import { InsightsDomain } from "./Insights";
import { IntegrationsDomain } from "./Integrations";
import { MomentDomain } from "./Moment";
import { OneOnOnesDomain } from "./OneOnOnes";
import { PeopleDomain } from "./People";
import { PlannerDomain } from "./Planner";
import { ProjectsDomain } from "./Projects";
import { SlackDomain } from "./Slack";
import { TasksDomain } from "./Tasks";
import { TeamDomain } from "./team/Team";
import { Domain } from "./types";
import { UsersDomain } from "./Users";
import { WeeklyReportsDomain } from "./WeeklyReports";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

export const LOCAL_TIME_FORMAT = "HH:mm:ss";
export abstract class DomainsConstructor {
  users: UsersDomain;
  accounts: AccountsDomain;
  credentials: CredentialsDomain;
  calendars: CalendarsDomain;
  calendarSyncPolicies: CalendarSyncPolicyDomain;
  events: EventsDomain;
  habits: HabitsDomain;
  tasks: TasksDomain;
  projects: ProjectsDomain;
  insights: InsightsDomain;
  people: PeopleDomain;
  slack: SlackDomain;
  oneOnOnes: OneOnOnesDomain;
  weeklyReports: WeeklyReportsDomain;
  integrations: IntegrationsDomain;
  planner: PlannerDomain;
  moment: MomentDomain;
  team: TeamDomain;
}

export class Client extends DomainsConstructor {
  private static DomainClasses = {
    users: UsersDomain,
    accounts: AccountsDomain,
    credentials: CredentialsDomain,
    calendars: CalendarsDomain,
    calendarSyncPolicies: CalendarSyncPolicyDomain,
    events: EventsDomain,
    habits: HabitsDomain,
    tasks: TasksDomain,
    projects: ProjectsDomain,
    insights: InsightsDomain,
    people: PeopleDomain,
    slack: SlackDomain,
    oneOnOnes: OneOnOnesDomain,
    weeklyReports: WeeklyReportsDomain,
    integrations: IntegrationsDomain,
    planner: PlannerDomain,
    moment: MomentDomain,
    team: TeamDomain
  };

  private domains: Record<string | number | symbol, Domain> = {};
  private proxy: Client;
  public client: ReclaimApi;
  public ws: ReclaimWS;

  constructor();
  constructor(baseUrl: string);
  constructor(client: ReclaimApi);
  constructor(config?: ReclaimApi | string) {
    super();

    if (config instanceof ReclaimApi) {
      this.client = config;
    } else {
      this.client = new ReclaimApi({ baseUrl: config || API_BASE_URI, BUILD_ID });
    }

    if (typeof WebSocket !== "undefined") this.ws = new ReclaimWS();

    this.proxy = new Proxy(this, {
      get: function (target, key: keyof Client) {
        if (key === "client") return target.client;
        if (Client.DomainClasses[key]) {
          return target.getDomain(key as keyof DomainsConstructor);
        }
      },
    });

    return this.proxy;
  }

  private getDomain(key: keyof DomainsConstructor) {
    if (!this.domains[key] && !Client.DomainClasses[key]) return;
    if (!this.domains[key]) {
      const DomainClass = Client.DomainClasses[key];
      this.domains[key] = new DomainClass(key, this.client, this.proxy, this.ws);
    }
    return this.domains[key];
  }
}

export const reclaim: Client = new Client();
